// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-compilation-js": () => import("./../src/pages/compilation.js" /* webpackChunkName: "component---src-pages-compilation-js" */),
  "component---src-pages-context-js": () => import("./../src/pages/context.js" /* webpackChunkName: "component---src-pages-context-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-react-js": () => import("./../src/pages/react.js" /* webpackChunkName: "component---src-pages-react-js" */)
}

